var columns = [{
  dataIndex: 'imgurl',
  key: 'imgurl',
  width: '1472px',
  scopedSlots: {
    customRender: 'content'
  }
}, {
  dataIndex: 'action',
  key: 'action',
  width: '89px',
  fixed: 'right',
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };