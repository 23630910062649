var searchKeys = [{
  key: "createdate",
  label: "创建时间",
  placeholder: ['开始日期', '截止日期'],
  required: false,
  rules: [],
  dates: true
}, {
  key: "orderno",
  label: "订单号",
  placeholder: "订单号",
  required: false,
  rules: [],
  input: true
}, {
  key: "blockchain",
  label: "Block Chain",
  placeholder: "",
  required: false,
  rules: [],
  input: true
  // select: true,
  // initialValue: '',
}, {
  key: "itemname",
  label: "商品名称",
  placeholder: "",
  required: false,
  rules: [],
  input: true
}, {
  key: "itemaddress",
  label: "Contract Address",
  placeholder: "",
  required: false,
  rules: [],
  input: true
}, {
  key: "creator",
  label: "Creator",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: "buyeremail",
  label: "买家邮箱",
  placeholder: "",
  required: false,
  rules: [],
  input: true
}, {
  key: "tokenid",
  label: "Token ID",
  placeholder: "",
  required: false,
  rules: [],
  input: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };